import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import router from './router'
import store from './store'
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
Vue.config.productionTip = false
import hljs from 'highlight.js';
Vue.use(hljs);
import SysLoginComponents from './components/SysLoginComponents.vue';
Vue.component('SysLoginComponents', SysLoginComponents);
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')

//封装成一个指令,highlight是指令的名称
Vue.directive('highlight', (el) => {
	let blocks = el.querySelectorAll('pre code')
	blocks.forEach((block) => {
		hljs.highlightBlock(block)
	})
})