import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/collect',
		name: 'collect',
		component: () => import('../views/CollectView.vue')
	},
	{
		path: '/user',
		name: 'user',
		component: () => import('../views/SysUser.vue')
	},
	{
		path: '/dataDown',
		name: 'dataDown',
		component: () => import('../views/SysDown.vue')
	},
	{
		path: '/calendar',
		name: 'calendar',
		component: () => import('../views/SysCalendar.vue')
	},
	{
		path: '/monitor',
		name: 'monitor',
		component: () => import('../views/SysMonitor.vue')
	},
	{
		path: '/laboratory',
		name: 'laboratory',
		component: () => import('../views/SysLaboratory.vue')
	},
	{
		path: '/contribution',
		name: 'contribution',
		component: () => import('../views/SysContribution.vue')
	},
	{
		path: '/chinaWarning',
		name: 'chinaWarning',
		component: () => import('../views/SysChinaWarning.vue')
	},
	{
		path: '/todayWordCloud',
		name: 'todayWordCloud',
		component: () => import('../views/SysTodayWordCloud.vue')
	},
	{
		path: '/hotShengJiangTu',
		name: 'hotShengJiangTu',
		component: () => import('../views/SysHotShengJiangTu.vue')
	},
	{
		path: '/d3view',
		name: 'd3view',
		component: () => import('../views/SysD3view.vue')
	}
]

const router = new VueRouter({
	mode: "history",
	routes
})

export default router