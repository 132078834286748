import axios from 'axios'
import Qs from 'qs'
import router from '../router/index.js'
import {
    fnGetToken,
    fnRemoveToken
} from './auth.js'
import {
    decrypt
} from './encryption.js'
import {
    Message,
    MessageBox,
    Notification
} from 'element-ui'
import {EventBus} from "@/util/eventBus";

// 创建一个axios实例
const service = axios.create({
    baseURL: 'https://hotengineapi.zhaoyizhe.com/hotEngineApi/',
    //baseURL: 'http://127.0.0.1:7909/hotEngineApi/',
    transformRequest: [function (data) {
        return Qs.stringify(data)
    }],
    timeout: 30000
});

service.interceptors.request.use(
    config => {
        if (fnGetToken()) {
            config.headers['wbrsnew'] = fnGetToken()
        }
        return config
    },
    error => {
        console.log('拦截错误', error)
        return Promise.reject()
    }
)

// 添加相应拦截器
service.interceptors.response.use(response => {
    const decryptData = JSON.parse(decrypt(response.data))
    response.data = decryptData
    if (decryptData.code === 998 || decryptData.code === 999 || decryptData.code === -1) {
        // 提示
        Notification({
            "title": decryptData.message,
            "message": "",
            "offset": 200
        })
    }
    if (decryptData.code === 0) {
        // 提示
        fnRemoveToken()
        Message.info(decryptData.message);
    }
    if (decryptData.code === 1000 || decryptData.code === 1003) {
        Message.info({
            "message": decryptData.message,
            "offset": 100,
            "duration": 1500,
            "type": "warning"
        })
        setTimeout(() => {
            EventBus.$emit('open-login-dialog');
        }, 1500);
    }
    if (decryptData.code === 1001 || decryptData.code === 1004) {
        MessageBox.confirm(decryptData.message, '提示', {
            confirmButtonText: '去续费',
            type: 'warning'
        }).then(() => {
            router.push({
                name: 'user',
                query: {
                    isShow: true
                }
            })
        }).catch(() => {
        });
    }
    if (decryptData.code === 1002) {
        MessageBox.confirm(decryptData.message, '提示', {
            confirmButtonText: '去充值',
            type: 'warning'
        }).then(() => {
            router.push({
                name: 'user',
                query: {
                    downShow: true
                }
            })
        }).catch(() => {
        });
    }
    return response;
}, error => {
    return Promise.reject(error);
})

export default service