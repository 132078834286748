<template>
  <div>
    <div class="indexLunbo">
      <el-carousel
        v-loading="loadingBanner"
        :interval="7000"
        height="150px"
        indicator-position="outside"
      >
        <el-carousel-item
          v-for="(item,index) in bannerArr"
          :key="index"
        >
          <img
            :src="item.url"
            class="responsive-image"
            style="width: 100%;height: 100%;"
            @click="gotoBannerUrl(item.path)"
          >
        </el-carousel-item>
      </el-carousel>
    </div>
    <div id="dataScroll" />
    <div
      class="home"
      style="width: 80vw;margin: 5px auto 0 auto;display: flex;min-height: 100vh;"
    >
      <el-backtop />
      <div
        id="queryCard"
        style="width: 25%;height: 230px;"
      >
        <div style="padding-bottom: 20px;">
          <el-card
            class="box-card"
            style="height: 100%;"
          >
            <div
              slot="header"
              class="clearfix"
            >
              <span style="padding-left: 1px;">关键字/日期查询</span>
            </div>
            <div class="text item">
              <el-input
                v-model="keyword"
                class="input-with-select"
                placeholder="请输入搜索关键字"
              />
            </div>
            <div
              class="text item"
              style="padding-top: 10px;"
            >
              <el-date-picker
                v-model="startDate"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                style="width: 100%;"
                type="daterange"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div
              class="text item"
              style="padding-top: 10px;"
            >
              <el-button
                size="medium"
                style="width: 100%;"
                @click="getListInfo()"
              >
                查询
              </el-button>
            </div>
          </el-card>
          <el-card
            id="navCard"
            class="box-card"
            style="margin-top: 20px;letter-spacing:6px;"
          >
            <div
              slot="header"
              class="clearfix"
            >
              <span style="padding-left: 1px;">快捷导航</span>
            </div>
            <div style="display: flex;font-size: 12px;font-weight: bold;overflow-x: auto;width: 100%;">
              <div
                style="flex: 10%;margin: 3px;"
                @click="gotoUser()"
              >
                <el-button
                  circle
                  icon="el-icon-user"
                  type="info"
                />
                <p style="margin-top: 5px;">
                  个人中心
                </p>
              </div>
              <div style="flex: 10%;margin: 3px;">
                <el-popover
                  placement="top-start"
                  width="50"
                  trigger="hover"
                >
                  <div style="text-align: center;">
                    <img
                      style="width: 100%;"
                      src="../assets/wxyizhishuwei.jpg"
                      alt="热搜引擎公众号"
                    >
                    <span style="font-size: 12px;font-weight: bold;">微信扫码反馈</span>
                  </div>
                  <div slot="reference">
                    <el-button
                      circle
                      icon="el-icon-date"
                      type="success"
                    />
                    <p style="margin-top: 5px;">
                      建议反馈
                    </p>
                  </div>
                </el-popover>
              </div>
              <div
                style="flex: 10%;margin: 3px;"
                @click="gotoDataDown()"
              >
                <el-button
                  circle
                  icon="el-icon-download"
                  type="primary"
                />
                <p style="margin-top: 5px;">
                  数据下载
                </p>
              </div>
            </div>
          </el-card>

          <el-card
            id="quanyiuser"
            class="box-card"
          >
            <entitlement-card
              entitlement="年度权益"
              expiry-date="2023-02-21 12:22:01"
            />
          </el-card>

          <el-card
            id="searchCardId"
            class="box-card"
            style="margin-top: 20px;margin-bottom: 20px;height: 400px;"
          >
            <div
              slot="header"
              class="clearfix"
            >
              <span style="padding-left: 1px;">搜索历史</span>
            </div>
            <div style="height: 100%;text-align: left;">
              <el-table
                :data="searchLogList"
                :show-header="false"
                height="350px"
                size="mini"
              >
                <el-table-column
                  label="关键词"
                  prop="keyword"
                />
                <el-table-column
                  align="right"
                  label="操作"
                >
                  <template slot-scope="scope">
                    <i
                      class="el-icon-search"
                      @click="searchLogQuery(scope.row)"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
          <el-card
              id="hyqydh"
              class="box-card"
          >
            <div
                slot="header"
                class="clearfix"
            >
              <span style="padding-left: 1px;">会员权益兑换码</span>
            </div>
            <div style="height: 100%;text-align: center;overflow-x: auto;width: 100%;">
              <SysSecretKey />
            </div>
            <div style="text-align: center;font-size: 14px;margin-top: 5px;">
              你可复制未被使用的兑换码,复制后粘贴到个人中心-兑换码中,同一活动周期仅能兑换一次,向右滑动可查看更多兑换码
            </div>
          </el-card>
          <div id="jinriciyun" style="margin-top: 20px;margin-bottom: 20px;">
            <TodayWordCloudComponents />
          </div>
        </div>
      </div>

      <div
        id="searchCary"
        style="width: 75%;margin-left: 10px;margin-bottom: 20px;"
      >
        <el-card
          id="boxCary"
          class="box-card"
          shadow="never"
          style="height: 50px;border: 0;border-radius: 0;padding-bottom: 5px;"
        >
          <div class="text item">
            <div
              class="searchCaryTitle"
              style="float: left;display: flex;"
            >
              <div style="margin-top: 6px;">
                <el-link type="info">
                  平台分类
                </el-link>
              </div>
              <div
                class="info"
                style="margin-top: 2px;margin-left: 10px;"
              >
                <el-radio-group
                  v-model="radioType"
                  size="mini"
                  @change="radioTypeUpdate"
                >
                  <el-radio-button :label="1">
                    微博
                  </el-radio-button>
                  <el-radio-button :label="2">
                    抖音热点
                  </el-radio-button>
                  <el-radio-button :label="3">
                    知乎热榜
                  </el-radio-button>
                  <el-radio-button :label="4">
                    头条热榜
                  </el-radio-button>
                  <el-radio-button :label="5">
                    百度
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
        </el-card>
        <el-card
          v-if="radioType===1||radioType===5"
          id="boxCary"
          class="box-card"
          shadow="never"
          style="height: 60px;border: 0;border-radius: 0;padding-bottom: 5px;"
        >
          <div class="text item">
            <div
              class="searchCaryTitle"
              style="float: left;display: flex;"
            >
              <div style="margin-top: 6px;">
                <el-link type="info">
                  细分维度
                </el-link>
              </div>
              <div
                class="info"
                style="margin-top: 2px;margin-left: 10px;"
              >
                <el-radio-group
                  v-model="radioTypeInfo"
                  size="mini"
                  @change="radioTypeInfoUpdate"
                >
                  <el-radio-button
                    v-if="radioType==1"
                    :label="1"
                    title="最早支持到19年10月"
                  >
                    总榜
                  </el-radio-button>
                  <el-radio-button
                    v-if="radioType==1"
                    :label="2"
                  >
                    文娱榜
                  </el-radio-button>
                  <el-radio-button
                    v-if="radioType==1"
                    :label="4"
                    title="最早支持到23年6月"
                  >
                    实时上升榜
                  </el-radio-button>
                  <el-radio-button
                    v-if="radioType==5"
                    :label="3"
                  >
                    热榜
                  </el-radio-button>
                  <el-radio-button
                    v-if="radioType==5"
                    :label="4"
                  >
                    小说
                  </el-radio-button>
                  <el-radio-button
                    v-if="radioType==5"
                    :label="5"
                  >
                    电影
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </div>
        </el-card>
        <el-card
          class="box-card"
          style="margin-top: 5px;margin-bottom: 20px;"
        >
          <el-table
            id="dataTable"
            v-loading="loading"
            :data="dataList"
            class="watermarked-table"
            stripe
            style="width: 100%;"
          >
            <el-table-column
              label=""
              width="60px"
            >
              <template slot-scope="scope">
                <div
                  v-if="scope.$index + (pageNo - 1) * pageSize + 1<=2"
                  style="font-weight: 700;color: #f26d5f;font-size: 16px;text-align: center;"
                >
                  {{ scope.$index + (pageNo - 1) * pageSize + 1 }}
                </div>
                <div
                  v-if="scope.$index + (pageNo - 1) * pageSize + 1>2"
                  style="font-weight: 500;color: #ff8200;font-size: 15px;text-align: center;"
                >
                  {{ scope.$index + (pageNo - 1) * pageSize + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="((radioType==1&&radioTypeInfo==3)?'事件名称':'话题')"
              width="300px"
            >
              <template slot-scope="scope">
                <div style="display: flex;">
                  <span style="font-size: 15px;">
                    {{ scope.row.topic }}{{ scope.row.title }}{{ scope.row.word }}
                  </span>
                  <span v-if="radioType==1&&radioTypeInfo==3">{{ scope.row.id }}</span>
                  <el-tag
                    v-if="scope.row.icon=='hot'"
                    size="mini"
                    style="margin-right: 5px;"
                    type="danger"
                  >
                    热
                  </el-tag>
                  <el-tag
                    v-if="scope.row.icon=='boil'"
                    size="mini"
                    style="margin-right: 5px;"
                    type="danger"
                  >
                    沸
                  </el-tag>
                  <el-tag
                    v-if="scope.row.icon=='boom'"
                    size="mini"
                    style="margin-right: 5px;color:RGB(189,0,0);"
                    type="danger"
                  >
                    爆
                  </el-tag>
                  <el-tag
                    v-if="scope.row.icon=='new'"
                    size="mini"
                    style="margin-right: 5px;"
                    type="danger"
                  >
                    新
                  </el-tag>
                  <el-tag
                    v-if="scope.row.icon=='jian'"
                    size="mini"
                    style="margin-right: 5px;"
                    type="danger"
                  >
                    荐
                  </el-tag>
                  <el-tag
                    v-if="scope.row.icon=='ju'"
                    size="mini"
                    style="margin-right: 5px;"
                    type="danger"
                  >
                    剧
                  </el-tag>
                  <el-tag
                    v-if="scope.row.fenlei!=null"
                    size="mini"
                    style="margin-right: 5px;"
                    type="info"
                  >
                    {{ scope.row.fenlei }}
                  </el-tag>
                  <el-tag
                    v-if="scope.row.location!=null"
                    size="mini"
                    style="margin-right: 5px;"
                    type="info"
                  >
                    {{ scope.row.location }}
                  </el-tag>
                </div>
                <div
                  v-if="scope.row.date!=null||scope.row.time!=null"
                  style="font-size: 12px;color: darkgrey;"
                >
                  最后在榜时间：{{ scope.row.date }}{{ scope.row.time }}
                </div>
                <div
                  v-if="scope.row.updateTime!=null&&radioType==5"
                  style="font-size: 12px;color: darkgrey;"
                >
                  最后在榜时间：{{ scope.row.updateTime }}
                </div>
                <div
                  v-if="radioType==1&&radioTypeInfo==3"
                  style="font-size: 12px;color: darkgrey;"
                >
                  在榜时间：{{ scope.row.updateTime }}
                </div>
                <div
                  v-if="scope.row.screenName!=null"
                  style="font-size: 12px;color: darkgrey;"
                >
                  主持人：{{ scope.row.screenName }}
                </div>
                <div
                  v-if="radioType==1&&radioTypeInfo==5&&scope.row.infos!=null"
                  style="font-size: 12px;color: darkgrey;"
                >
                  🖊{{ scope.row.infos[0].discuss }}👁{{ scope.row.infos[0].read }}
                </div>
                <div
                  v-if="radioType==1&&radioTypeInfo==5"
                  style="font-size: 12px;color: darkgrey;"
                >
                  在榜时间：{{ scope.row.createTime }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="radioType==2 ||(radioType==1&&(radioTypeInfo==1||radioTypeInfo==2))"
              label="在榜时长"
              prop="durations"
            />
            <el-table-column
              v-if="radioType==1&&radioTypeInfo==4"
              label="在榜时间"
              prop="createTime"
            />
            <el-table-column
              v-if="radioType==1&&radioTypeInfo==5"
              label="导语"
              prop="content"
            />
            <el-table-column
              v-if="radioType==1&&(radioTypeInfo==1||radioTypeInfo==2)"
              label="最高排名"
              prop="pm"
            />
            <el-table-column
              v-if="radioType==1&&radioTypeInfo==3"
              label="讨论量"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.mention!=null">
                  {{ scope.row.mention }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="radioType==1&&radioTypeInfo==4"
              label="热度/标签"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.infos!=null">
                  {{ scope.row.infos[0].descExtr }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="!(radioType==1&&(radioTypeInfo==3||radioTypeInfo==4||radioTypeInfo==5))"
              label="热度"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.hotScore!=null">
                  {{ scope.row.hotScore }}
                </div>
                <div v-if="scope.row.hotNumber!=null">
                  {{ scope.row.hotNumber }}
                </div>
                <div v-if="scope.row.metrics!=null">
                  {{ scope.row.metrics }}万
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="150"
            >
              <template slot-scope="scope">
                <div style="display: flex;">
                  <el-tooltip
                    v-if="radioType==1&&radioTypeInfo!=4&&radioTypeInfo!=5"
                    class="item"
                    content="点击进入详情页"
                    effect="dark"
                    placement="top-start"
                  >
                    <el-tag
                      effect="plain"
                      size="mini"
                      type="info"
                      @click="getInfo(scope.row)"
                    >
                      <i
                        class="el-icon-info"
                      />
                    </el-tag>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    content="点击进入源网站"
                    effect="dark"
                    placement="top-start"
                    style="margin: 0px 5px;"
                  >
                    <el-tag
                      effect="plain"
                      size="mini"
                      @click="gotoSouce(scope.row)"
                    >
                      <i
                        class="el-icon-share"
                      />
                    </el-tag>
                  </el-tooltip>
                  <el-tooltip
                    v-if="radioType==1 && radioTypeInfo==1&&radioTypeInfo!=4"
                    class="item"
                    content="热搜走势"
                    effect="dark"
                    placement="top-start"
                    style="margin: 0 5px;"
                  >
                    <el-tag
                      effect="plain"
                      size="mini"
                      @click="showZoushi(scope.row)"
                    >
                      <i
                        class="el-icon-s-data"
                      />
                    </el-tag>
                  </el-tooltip>
                  <el-popover
                    id="shoucang"
                    :value="elPopoverVisible"
                    placement="top"
                    style="padding: 0px !important;"
                    width="170"
                  >
                    <p style="font-size: 12px;">
                      选择一个标签,添加到您的收藏列表里,如果没有,可直接输入创建
                    </p>
                    <div>
                      <el-select
                        v-model="topicTagValue"
                        allow-create
                        default-first-option
                        filterable
                        placeholder="输入或下拉选择"
                        size="mini"
                      >
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </div>
                    <div style="text-align: right; margin-top: 10px">
                      <el-button
                        size="mini"
                        type="primary"
                        @click="saveTopicTag(scope.row)"
                      >
                        确定
                      </el-button>
                    </div>
                    <el-tag
                      slot="reference"
                      effect="plain"
                      size="mini"
                      type="warning"
                    >
                      <i
                        class="el-icon-star-off"
                      />
                    </el-tag>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            id="pcPage"
            :current-page.sync="pageNo"
            :page-size="10"
            :page-sizes="pageSizes"
            :total="dataListTotal"
            background
            class="pagena"
            layout="sizes,prev, pager, next"
            style="margin-top: 10px;"
            @current-change="pageNoChange"
            @size-change="sizeChange"
          />

          <el-pagination
            id="mobilePage"
            :current-page.sync="pageNo"
            :page-size="10"
            :page-sizes="pageSizes"
            :pager-count="5"
            :total="dataListTotal"
            background
            class="pagena"
            layout="sizes,prev, pager, next"
            small
            style="margin-top: 10px;"
            @current-change="pageNoChange"
            @size-change="sizeChange"
          />
        </el-card>
      </div>
    </div>

    <el-dialog
      id="rszst"
      :visible.sync="zoushiDialog"
      title="热度走势图"
      @opened="initChart"
    >
      <div
        ref="myChart"
        v-loading="zoushiLoading"
        style="margin: auto;width: 100%; height: 400px;"
      />
    </el-dialog>
    <el-dialog
      :visible.sync="updateDialogVisible"
      center
      width="30%"
      @close="updateClose()"
      @closed="updateClose()"
    >
      <el-card style="margin-top: 5px;letter-spacing:6px;">
        <div
          slot="header"
          class="clearfix"
        >
          <span style="padding-left: 1px;">更新说明</span>
        </div>
        <div style="text-align: left;font-size: 14px;font-weight: bold;">
          感谢各位同学支持微博历史热搜-微博热搜搜索引擎，为了大家更好的体验，即日起将开启全新站点，你也可以
          <a
            href="https://weibo.zhaoyizhe.com/old/index.html"
            target="_blank"
          >点我访问旧版</a>。
        </div>
        <br>
        <div style="text-align: left;font-size: 14px;font-weight: bold;">
          即日起，原专业版将逐步更新，权益对应会进一步修改。该项目为限时公测版本，如您有任何的建议或意见或者想法，
          <a
            href="https://support.qq.com/product/69071"
            style="color: red;"
            target="_blank"
          >欢迎点我反馈</a>。
        </div>
        <br>
        <div style="text-align: left;font-size: 14px;font-weight: bold;">
          本项目还有很多的不足，我将会逐步完善，在此也感谢各位同学的不离不弃和理解！也希望更多有想法有见解的行业内同学，能够一起参与到这个项目中，一起见证它的成长。
        </div>
        <br>
        <div style="text-align: left;font-size: 14px;font-weight: bold;">
          在此暂时指路<a
            href="https://super.zhaoyizhe.com"
            target="_blank"
          >专业版链接</a>,敬请期待更多实用功能及有意思的需求！
        </div>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import {getList, superInfo} from "../api/homeData.js";
import * as echarts from 'echarts';
import SysSecretKey from '@/components/SysSecretKey.vue';
import EntitlementCard from '@/components/EntitlementCard.vue';
import TodayWordCloudComponents from '@/components/TodayWordCloudComponents.vue';
import {bannerList, saveCollectTag, saveRecommend, searchLog, tagList} from "../api/user.js";

export default {
  components: {
    EntitlementCard,
    SysSecretKey,
    TodayWordCloudComponents
  },
  data() {
    return {
      pagerCount: "3",
      updateDialogVisible: false,
      radioType: 1,
      radioTypeInfo: 1,
      dataList: [],
      keyword: null,
      startDate: null,
      dataListTotal: 0,
      pageSize: 10,
      pageSizes: [10, 50, 100],
      pageNo: 1,
      searchLogList: [],
      loading: true,
      topicTagValue: null,
      options: [],
      topicTagList: [],
      bannerArr: [],
      elPopoverVisible: false,
      loadingBanner: false,
      zoushiDialog: false,
      topic: "",
      zoushiLoading: false
    }
  },
  created() {
    var that = this;
    // if (!localStorage.getItem('updateWeb')) {
    // 	that.updateDialogVisible = true
    // }
    that.getBannerList()
    that.getListInfo();
    that.getSearchLog()
    that.getTagList()
    if (!this.$store.state.login) {
      // 未登录状态下,只能10条/页
      that.pageSizes = [10]
    } else {
      that.pageSizes = [10, 20, 50, 100, 200, 500]
    }
  },
  mounted() {
    var that = this;
    let geturl = window.location.href
    let getqys = new URLSearchParams('?' + geturl.split('?')[1])
    let searchKey = getqys.get('keyword')
    if (searchKey !== null && searchKey !== undefined && searchKey != "") {
      document.title = searchKey + '-热搜引擎-微博历史热搜-微博热搜搜索引擎';
      that.keyword = searchKey
      that.getListInfo();
    }
    let aff = getqys.get('aff')
    if (aff !== null && aff !== undefined && aff != "") {
      localStorage.setItem('aff', aff);
      that.saveRecommend(aff)
    } else if (localStorage.getItem('aff') !== null && localStorage.getItem('aff') != undefined && localStorage
        .getItem('aff') != "") {
      that.saveRecommend(localStorage.getItem('aff'))
    }
  },
  methods: {
    gotoTuijian() {
      this.$router.push("/user", () => {
      }, () => {
      })
    },
    saveRecommend(aff) {
      // 推荐
      saveRecommend(aff).then(res => {
        console.log(res.data.data.code)
      }).catch((err) => {
        console.log(err)
      })
    },
    updateClose() {
      // 查询缓存中的标记
      // localStorage.setItem('updateWeb', "pass");
    },
    initChart() {
      this.zoushiLoading = true;
      const chartDom = this.$refs.myChart;
      const myChart = echarts.init(chartDom);
      superInfo(this.topic).then(res => {
        this.zoushiLoading = false;
        const option = {
          title: {
            text: this.topic
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              params = params[0];
              var label = params.value[2];
              var labelStr = "";
              if (label === 'hot') labelStr = "热";
              if (label === 'new') labelStr = "新";
              if (label === 'boil') labelStr = "沸";
              if (label === 'boom') labelStr = "爆";
              if (label === 'jian') labelStr = "荐";
              if (label === 'ju') labelStr = "剧";
              if (labelStr === "") {
                return '热度：' + params.value[1] + '<br/>' + params.value[0];
              } else {
                return '热度：' + params.value[1] + ' <br/>标签：' + labelStr + '<br/>' +
                    params.value[0];
              }
            }
          },
          xAxis: {
            type: 'time',
            splitLine: {
              show: true
            }
          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            splitLine: {
              show: false
            }
          },
          series: [{
            name: '走势图',
            type: 'line',
            showSymbol: false,
            hoverAnimation: false,
            data: res.data.data
          }],
          graphic: [{
            type: 'text',
            left: 'center', // 文字水平对齐方式
            top: 'bottom', // 文字垂直对齐方式
            style: {
              text: '初次上榜时间:' + res.data.data[0].value[0] + '\n最后在榜时间:' + res.data
                  .data[res.data.data.length - 1].value[0] + '\n共计在榜:' + res.data
                  .data.length + '分钟', // 要显示的文本内容
              textAlign: 'center',
              font: '14px Microsoft YaHei', // 设置字体样式
              fill: '#6b6b6b', // 设置文本颜色
              fontWeight: 'bold', // 设置字体粗细
              textWrap: true, // 文本换行
              lineHeight: 15 // 行高
            }
          },
            {
              type: 'text',
              left: 'center', // 水印水平对齐方式
              top: 'middle', // 水印垂直对齐方式
              z: 100, // 设置z值，确保水印在图表元素的上层
              style: {
                text: '热搜引擎', // 要显示的水印文本内容
                textAlign: 'center',
                font: '30px Microsoft YaHei', // 设置字体样式
                fill: 'rgba(0, 0, 0, 0.1)', // 设置文本颜色和透明度
                fontWeight: 'bold' // 设置字体粗细
              }
            }
          ]
        };
        myChart.setOption(option);
        window.addEventListener('resize', () => {
          myChart.resize();
        });
      }).catch((err) => {
        console.log(err)
      })
    },
    showZoushi(row) {
      console.log(row)
      this.topic = row.topic
      this.zoushiDialog = true
    },
    gotoUser() {
      this.$router.push('/user', () => {
      }, () => {
      })
    },
    gotoJianyi() {
      window.open("https://support.qq.com/product/69071")
    },
    gotoOld() {
      window.open("https://super.zhaoyizhe.com/")
    },
    gotoDataDown() {
      this.$router.push('/dataDown', () => {
      }, () => {
      })
    },
    gotoBannerUrl(path) {
      if (path != '' && path != null) {
        this.$router.push(path, () => {
        }, () => {
        })
      }
    },
    getBannerList() {
      var that = this;
      that.loadingBanner = true;
      bannerList().then(res => {
        that.bannerArr = res.data.data
        that.loadingBanner = false;
      }).catch((err) => {
        console.log(err)
        that.loadingBanner = false;
      })
    },
    searchLogQuery(val) {
      var that = this;
      that.keyword = val.keyword
      this.getListInfo();
    },
    saveTopicTag(val) {
      var that = this;
      var topic = val.topic;
      if (that.radioType === 3) {
        topic = val.title;
      }
      if (that.radioType === 5) {
        topic = val.word;
      }
      saveCollectTag(topic, that.topicTagValue, that.radioType, that.radioTypeInfo).then(res => {
        that.getTagList()
        if (res.data.code === 1) {
          this.$notify({
            title: '保存成功',
            message: '已保存到[我的收藏]',
            offset: 100,
            duration: 1000
          });
        }
      }).catch((err) => {
        console.log(err)
      })
      that.elPopoverVisible = false
    },
    getTagList() {
      var that = this;
      tagList().then(res => {
        that.options = res.data.data
      }).catch((err) => {
        console.log(err)
      })
    },
    getSearchLog() {
      var that = this;
      searchLog().then(res => {
        that.searchLogList = res.data.data
      }).catch((err) => {
        console.log(err)
      })
    },
    getInfo(val) {
      var that = this;
      if (that.radioType == 1) {
        window.open("https://weibo.zhaoyizhe.com/info/" + val.id + "/")
      }
    },
    gotoSouce(val) {
      var that = this;
      if (that.radioType === 1) {
        window.open("https://s.weibo.com/weibo?q=%23" + val.topic + "%23")
      }
      if (that.radioType === 2) {
        window.open("https://www.douyin.com/search/" + val.topic)
      }
      if (that.radioType === 3 && val.topicId == null) {
        window.open("https://www.zhihu.com/search?type=content&q=" + val.title)
      }
      if (that.radioType == 3 && val.topicId != null) {
        window.open("https://www.zhihu.com/question/" + val.topicId)
      }
      if (that.radioType == 4) {
        window.open(
            "https://so.toutiao.com/search?wid_ct=1678090308305&dvpf=pc&source=trending_card&keyword=" +
            val.topic)
      }
      if (that.radioType == 5) {
        window.open("https://www.baidu.com/s?wd=" + val.word)
      }
    },
    backTop() {
      var timer = setInterval(function () {
        let osTop =
            document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
            osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 80);
    },
    sizeChange(val) {
      var that = this;
      that.pageNo = 1
      that.pageSize = val
      this.getListInfo()
    },
    pageNoChange(val) {
      var that = this;
      that.pageNo = val
      this.getListInfo()
      document.querySelector('#dataScroll').scrollIntoView(true)
    },
    radioTypeUpdate: function () {
      var that = this;
      that.pageNo = 1
      if (that.radioType === 5) {
        that.radioTypeInfo = 3;
      }
      if (that.radioType === 1) {
        that.radioTypeInfo = 1;
      }
      this.getListInfo()
    },
    radioTypeInfoUpdate: function () {
      var that = this;
      that.pageNo = 1
      this.getListInfo()
    },
    getListInfo() {
      var that = this;
      that.loading = true
      var startDatea = null;
      var endDatea = null;
      if (that.startDate != null) {
        startDatea = that.startDate[0]
        endDatea = that.startDate[1]
      }
      getList(startDatea, endDatea, that.radioType, that.pageNo, that.pageSize, that.keyword, that.radioTypeInfo)
          .then(
              res => {
                if (res.data.data != null) {
                  that.dataList = res.data.data.data.data
                  that.dataListTotal = res.data.data.data.total
                } else {
                  that.dataList = []
                }
                that.loading = false
                that.getSearchLog()
              }).catch((err) => {
        this.loading = false
        console.log(err)
      })
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: rgb(247, 248, 249) !important;
}

.tuijianLogo {
  position: fixed;
  bottom: 45%;
  right: 10px;
}

.tuijianLogo img {
  transition: transform 0.3s ease-in-out;
}

.tuijianLogo:hover img {
  transform: scale(1.2);
}

@media (min-width: 769px) {
  #mobilePage {
    display: none !important;
  }
}

@media (max-width: 768px) {
  #rszst .el-dialog {
    width: 90%;
  }

  #jinriciyun{
    display: none !important;
  }

  #pcPage {
    display: none !important;
  }

  #quanyiuser{
    display: none !important;
  }

  #TodayWordCloudComponents{
    display: none !important;
  }

  #hyqydh{
    display: none !important;
  }

  .searchCaryTitle {
    display: inherit !important;
    float: none !important;
  }

  #searchCary .el-card__body {
    padding: 5px !important;
  }

  #searchCary .info {
    margin-left: 0px !important;
  }

  #boxCary {
    height: 70px !important;
  }

  .indexLunbo {
    display: none !important;
  }

  #navCard {
    display: none !important;
  }

  #searchCardId {
    display: none !important;
  }

  .home {
    display: initial !important;
  }

  #queryCard {
    width: 90% !important;
    margin: 100px auto 10px auto !important;
  }

  #searchCary {
    width: 95% !important;
  }

  #searchCary .el-radio {
    margin-right: 5px !important;
  }

  .el-picker-panel {
    max-width: 100%;
  }

  /* 修改 el-date-range-picker 的宽度以适应移动设备 */
  .el-date-range-picker {
    width: 100%;
  }

  .el-pagination {
    white-space: normal !important;
  }
}

#shoucang {
  line-height: 0px;
}

#headerSys {
  margin: 0 !important;
}

.el-carousel__indicators--outside {
  display: none;
}

#searchCardId .el-card__body {
  padding: 5px;
  height: 500px;
}

#searchCardId {
  min-height: 100% !important;
  height: 300px;
  bottom: 20px;
}

#ads {
  bottom: 20px;
}

.watermarked-table .el-table__body-wrapper {
  position: relative;
}

.watermarked-table .el-table__body-wrapper::before {
  content: "zhaoyizhe.com";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  pointer-events: none;
  z-index: 1000;
}

.watermarked-table .el-table__body-wrapper::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 170 90'%3E%3Ctext text-anchor='middle' x='50%25' y='50%25' dy='.3em' fill='rgba(0,0,0,.2)' font-size='25' font-weight='bold' transform='rotate(-30)'%3E热搜引擎%3C/text%3E%3C/svg%3E");
  background-repeat: repeat;
}
</style>