<template>
  <div class="headerBody">
    <div class="headerChPc">
      <div
        class="headerFavicon"
        @click="reIndex()"
      >
        <el-image
          id="headerFaviconImage"
          src="../static/favicon.png"
        />
      </div>
      <div class="headerNav">
        <el-header>
          <el-menu
            :default-active="$route.path"
            mode="horizontal"
            router
            @select="handleSelect"
          >
            <el-menu-item index="/">
              数据概览
            </el-menu-item>
            <el-menu-item index="/calendar">
              热点日历
            </el-menu-item>
            <el-menu-item index="/dataDown">
              数据下载中心
            </el-menu-item>
            <el-menu-item index="/contribution">
              微博贡献人
            </el-menu-item>
            <el-menu-item index="/laboratory">
              实验室
            </el-menu-item>
            <el-menu-item index="/user">
              个人中心
            </el-menu-item>
            <el-menu-item @click="gotoSuperTodo">
              超级待办
            </el-menu-item>
          </el-menu>
        </el-header>
      </div>
      <div style="margin-right: 30px;padding-top: 10px;">
        <el-dropdown
          v-if="isLogin"
          style="display: flex;"
        >
          <div>
            <el-avatar :src="avatarUrl" />
          </div>
          <div>
            <i
              class="el-icon-arrow-down el-icon--right"
              style="text-align: center;padding-top: 100%;"
            />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="gotoUser()">
              个人中心
            </el-dropdown-item>
            <el-dropdown-item @click.native="logout()">
              退出
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div
          v-if="!isLogin"
          style="padding-top: 12px;"
          @click="goLogin()"
        >
          <el-link
            type="primary"
            style="font-size: 16px;font-weight: bold;"
          >
            <i class="el-icon-user" />登录/注册
          </el-link>
        </div>
      </div>
    </div>
    <div class="headerMobile">
      <div style="height: 50px;background-color: indianred;">
        <div style="display: flex;">
          <div
            style="flex: 50%;line-height: 50px;text-align: left;padding-left: 5%;font-weight: bold;color: aliceblue;"
          >
            热搜引擎
          </div>
          <div style="padding-right: 5%;padding-top: 1.3%;">
            <el-dropdown
              v-if="isLogin"
              style="display: flex;color: aliceblue;"
            >
              <el-avatar :src="avatarUrl" />
              <div>
                <i
                  class="el-icon-arrow-down el-icon--right"
                  style="text-align: center;padding-top: 100%;"
                />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="gotoUser()">
                  个人中心
                </el-dropdown-item>
                <el-dropdown-item @click.native="logout()">
                  退出
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div
              v-if="!isLogin"
              style="line-height: 40px;color: aliceblue;"
              @click="goLogin()"
            >
              <el-link>
                登录/注册
              </el-link>
            </div>
          </div>
        </div>
        <div
          id="mobileMenu"
          style="margin: 10px;height: 80px;font-size: 15px;"
        >
          <el-card
            class="box-card"
            style="height: 100%;"
          >
            <div
              class="innerDiv"
              style="display: flex;flex-wrap: wrap;"
            >
              <div style="width: 20%;">
                <router-link to="/">
                  <el-button
                    circle
                    type="danger"
                  >
                    数据<br>概览
                  </el-button>
                </router-link>
              </div>
              <div style="width: 20%;">
                <router-link to="/calendar">
                  <el-button
                    circle
                    type="primary"
                  >
                    热点<br>日历
                  </el-button>
                </router-link>
              </div>
              <div style="width: 20%;">
                <router-link to="/dataDown">
                  <el-button
                    circle
                    type="success"
                  >
                    下载<br>中心
                  </el-button>
                </router-link>
              </div>
              <div style="width: 20%;">
                <el-button
                  circle
                  type="warning"
                  @click="goPcInfo()"
                >
                  更多<br>功能
                </el-button>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {info} from "@/api/user";
import {fnRemoveToken} from '@/util/auth';
import {EventBus} from "@/util/eventBus";

export default {
  data() {
    return {
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      activeIndex: '1',
      avatarUrl: "https://weibo.zhaoyizhe.com/images/avatar.png"
    };
  },
  computed: {
    isLogin() {
      this.getInfo()
      return this.$store.state.login
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    gotoSuperTodo(){
      window.open("https://todopage.yizhishuwei.com/");
    },
    goPcInfo() {
      this.$message("更多功能请前往PC端体验")
    },
    reIndex() {
      this.$router.go(0);
    },
    logout() {
      const that = this;
      fnRemoveToken()
      that.$store.commit("doLogout");
      this.$router.go(0);
    },
    getInfo() {
      var that = this;
      info().then(res => {
        if (res.data.code === 1 && res.data.data != null) {
          that.$store.commit("doLogin");
        } else {
          fnRemoveToken()
          that.$store.commit("doLogout");
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    goLogin() {
      EventBus.$emit('open-login-dialog');
    },
    gotoUser() {
      this.$router.push("/user", () => {
      }, () => {
      })
    },
    handleSelect(key) {
      this.$router.push(key, () => {
      }, () => {
      })
    }
  }
}
</script>

<style>
.headerNav .el-menu-item.is-active {
  background-color: rgba(255, 255, 255, 0) !important;
}

.headerNav .el-menu-item.is-active {
  background: rgba(255, 255, 255, 0) !important;
}

.headerBody {
  z-index: 100000;
  top: 0;
  position: sticky;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

@media (max-width: 768px) {
  .headerChPc {
    display: none !important;
  }
  .headerBody {
    z-index: 999;
  }
}

@media (min-width: 769px) {
  .headerMobile {
    display: none !important;
  }
}



.headerNav .el-menu {
  background: rgba(255, 255, 255, 0) !important;
}

.headerBody .headerChPc {
  width: 85%;
  min-width: 1100px;
  margin: 0 auto;
  display: flex;
  backdrop-filter: blur(20px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
}

.headerBody .headerChPc .headerFavicon {
  margin-left: 30px;
}

.headerBody .headerChPc .headerFavicon .headerFaviconImage {
  height: 50px;
  margin-top: 10px;
}

.headerNav {
  flex: auto;
  font-weight: bold;
}

.headerNav .el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.el-image__error,
.el-image__inner,
.el-image__placeholder {
  height: 80% !important;
}
</style>