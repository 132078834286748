import request from '../util/request.js'

export function getList(startDate,endDate,type,pageNo,pageSize,keyword,radioType) {
  return request({
    url: '/data/list?startDate='+startDate+'&endDate='+endDate+'&type='+type+'&pageNo='+pageNo+'&pageSize='+pageSize+'&keyword='+keyword+'&radioType='+radioType,
    method: 'get'
  })
}

export function superInfo(keyword) {
  return request({
    url: '/data/superInfo?keyword='+keyword,
    method: 'get'
  })
}

export function liftingDiagram(keyword) {
  return request({
    url: '/data/liftingDiagram?keyword='+keyword,
    method: 'get'
  })
}