<template>
  <el-dialog
    id="loginDialog"
    :visible.sync="dialogVisible"
    :width="dialogWidth + 'px'"
    top="23vh"
    :modal-append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div
      id="loginCard"
      ref="dialogContent"
      style="width: 900px;height: 500px;;margin:5% auto;"
    >
      <el-card
        class="box-card"
        style="margin: 0;padding: 0;"
      >
        <div style="display: flex;">
          <div
            id="leftLoginPage"
            style="width: 40%;height: 500px;color: white;background-color: aliceblue;background-image: url(../static/loginBg.png);"
          >
            <div>
              <div style="margin-top: 10%;">
                <img
                  src="../../public/static/favicon-w.png"
                  style=""
                  alt=""
                >
              </div>
              <div style="margin-top: 10%;text-align: left;font-size: 18px;margin-left: 50px;">
                <div
                  class="text item"
                  style="margin-top: 45px;letter-spacing:2px;font-weight: bold;"
                >
                  注册/登录解锁更多功能：
                </div>
                <div
                  class="text item"
                  style="margin-top: 20px;letter-spacing:2px;"
                >
                  注册即送会员权益
                </div>
                <div
                  class="text item"
                  style="margin-top: 20px;letter-spacing:2px;"
                >
                  页码选择更多
                </div>
                <div
                  class="text item"
                  style="margin-top: 20px;letter-spacing:2px;"
                >
                  查看数据无条数限制
                </div>
                <div
                  class="text item"
                  style="margin-top: 20px;letter-spacing:2px;"
                >
                  可查看词条详情(微博)
                </div>
                <div
                  class="text item"
                  style="margin-top: 20px;letter-spacing:2px;"
                >
                  可记录搜索历史
                </div>
              </div>
            </div>
          </div>
          <div style="flex: auto;width: 60%;">
            <el-tabs
              id="loginTypeTabs"
              v-model="activeName"
              stretch
              type="card"
              @tab-click="changeTab"
            >
              <el-tab-pane
                v-if="activeName=='plainLogin'||activeName=='wxLogin'"
                label="微信登录"
                name="wxLogin"
              >
                <div
                  v-loading="qrLoading"
                  style="width: 200px;height: 200px;margin: 40px auto;"
                >
                  <img
                    :src="ticketSrc"
                    style="width: 100%;"
                  >
                </div>
                <div style="color: darkgrey;">
                  <div>
                    微信扫描二维码登录/注册
                  </div>
                  <div>
                    如不使用微信,可
                    <el-link @click="gotoRegLogin()">
                      点我注册
                    </el-link>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                v-if="activeName==='plainLogin'||activeName==='wxLogin'"
                label="账户密码登录"
                name="plainLogin"
              >
                <div style="margin: 0 auto; width: 75%;height: 100%;">
                  <el-input
                    v-model="mobile"
                    placeholder="请输入登录账户"
                    style="margin-top: 20%;margin-bottom: 5%;"
                  >
                    <template slot="prepend">
                      +86
                    </template>
                  </el-input>
                  <el-input
                    v-model="password"
                    placeholder="请输入密码"
                  />
                  <div style="margin-top: 10%;width: 100%;">
                    <el-button
                      plain
                      style="width: 100%;"
                      type="primary"
                      @click="loginMes()"
                    >
                      登录
                    </el-button>
                  </div>
                  <div style="color: darkgrey;margin-top: 10%;">
                    <div>
                      手机号+密码登录
                    </div>
                    <div>
                      如没有账号,可
                      <el-link @click="gotoRegLogin()">
                        点我注册
                      </el-link>
                    </div>
                    <div>
                      忘记密码,可
                      <el-link @click="gotoForgetPassword()">
                        点我重置
                      </el-link>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                v-if="activeName==='regLogin'"
                label="注册账户"
                name="regLogin"
              >
                <div style="margin: 0 auto;width: 75%;;height: 100%;">
                  <div style="margin-top: 10%;display: flex;margin-bottom: 5%;">
                    <el-input
                      v-model="mobile"
                      placeholder="请输入11位注册手机号"
                    >
                      <template slot="prepend">
                        +86
                      </template>
                    </el-input>
                    <el-button
                      v-if="codeShow"
                      disabled
                    >
                      {{ codeCount }}s
                    </el-button>
                    <el-button
                      v-if="!codeShow"
                      @click="getCode()"
                    >
                      发送验证码
                    </el-button>
                  </div>
                  <el-input
                    v-model="code"
                    placeholder="请输入验证码"
                    style="margin-bottom: 5%;"
                  />
                  <el-input
                    v-model="password"
                    placeholder="请输入密码"
                    style="margin-bottom: 5%;"
                  />
                  <el-input
                    v-model="passwordAgain"
                    placeholder="请再次输入密码"
                  />
                  <div style="margin-top: 5%;width: 100%;;text-align:right;">
                    <el-button
                      plain
                      style="width: 100%;"
                      type="primary"
                      @click="regAccount()"
                    >
                      注册并登录
                    </el-button>
                  </div>
                  <div style="display: flex;margin-top: 50px;color: azure;">
                    <div style="flex: auto;">
                      <el-link
                        plain
                        type="info"
                        @click="gotoWx()"
                      >
                        返回微信登录
                      </el-link>
                    </div>
                    <div style="flex: auto;">
                      <el-link
                        plain
                        type="info"
                        @click="gotoPlainLogin()"
                      >
                        返回账号密码登录
                      </el-link>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                v-if="activeName==='forgetPassword'"
                label="忘记密码"
                name="forgetPassword"
              >
                <div style="margin: 0 auto;width: 75%;;height: 100%;">
                  <div style="margin-top: 10%;display: flex;margin-bottom: 5%;">
                    <el-input
                      v-model="mobile"
                      placeholder="请输入已注册手机号"
                    >
                      <template slot="prepend">
                        +86
                      </template>
                    </el-input>
                    <el-button
                      v-if="codeShow"
                      disabled
                    >
                      {{ codeCount }}s
                    </el-button>
                    <el-button
                      v-if="!codeShow"
                      @click="getForgetPasswordCode()"
                    >
                      发送验证码
                    </el-button>
                  </div>
                  <el-input
                    v-model="code"
                    placeholder="请输入验证码"
                    style="margin-bottom: 5%;"
                  />
                  <el-input
                    v-model="password"
                    placeholder="请输入密码"
                    style="margin-bottom: 5%;"
                  />
                  <el-input
                    v-model="passwordAgain"
                    placeholder="请再次输入密码"
                  />
                  <div style="margin-top: 5%;width: 100%;;text-align:right;">
                    <el-button
                      plain
                      style="width: 100%;"
                      type="primary"
                      @click="forgetPasswordAccount()"
                    >
                      确认修改
                    </el-button>
                  </div>
                  <div style="display: flex;margin-top: 50px;color: azure;">
                    <div style="flex: auto;">
                      <el-link
                        plain
                        type="info"
                        @click="gotoWx()"
                      >
                        返回微信登录
                      </el-link>
                    </div>
                    <div style="flex: auto;">
                      <el-link
                        plain
                        type="info"
                        @click="gotoPlainLogin()"
                      >
                        返回账号密码登录
                      </el-link>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                v-if="activeName==='bdAccount'"
                label="绑定账号"
                name="bdAccount"
              >
                <div style="margin: 0 auto;width: 75%;;height: 100%;">
                  <div>根据相关法律法规,需要完善手机号,请继续操作</div>
                  <div style="margin-top: 10%;display: flex;margin-bottom: 5%;">
                    <el-input
                      v-model="mobile"
                      placeholder="请输入手机号"
                    >
                      <template slot="prepend">
                        +86
                      </template>
                    </el-input>
                    <el-button
                      v-if="codeShow"
                      disabled
                    >
                      {{ codeCount }}s
                    </el-button>
                    <el-button
                      v-if="!codeShow"
                      @click="getBdCode()"
                    >
                      发送验证码
                    </el-button>
                  </div>
                  <el-input
                    v-model="code"
                    placeholder="请输入验证码"
                    style="margin-bottom: 5%;"
                  />
                  <el-input
                    v-if="bdPasswordShow"
                    v-model="password"
                    placeholder="请输入密码"
                    style="margin-bottom: 5%;"
                  />
                  <el-input
                    v-if="bdPasswordShow"
                    v-model="passwordAgain"
                    placeholder="请再次输入密码"
                  />
                  <div style="margin-top: 5%;width: 100%;;text-align:right;">
                    <el-button
                      plain
                      style="width: 100%;"
                      type="primary"
                      @click="bdRegisterButton()"
                    >
                      确认绑定
                    </el-button>
                  </div>
                  <div style="display: flex;margin-top: 50px;color: azure;">
                    <div style="flex: auto;">
                      <el-link
                        plain
                        type="info"
                        @click="gotoWx()"
                      >
                        返回登录
                      </el-link>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import {
  bdRegister,
  login,
  register,
  retrieveCode,
  retrievePassword,
  sendBdSms,
  sendCode,
  wxLoginQr,
  wxLoginQrIsLogin
} from "@/api/user";
import {fnSetToken} from '@/util/auth';
import {EventBus} from "@/util/eventBus";

export default {
  data() {
    return {
      dialogVisible: false,
      dialogWidth: 400,
      activeName: "wxLogin",
      mobile: "",
      password: "",
      ticketSrc: "",
      qrLoading: false,
      codeShow: false,
      codeCount: 30,
      code: null,
      passwordAgain: "",
      timerFlag: "",
      openId: "",
      bdPasswordShow: false
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.adjustDialogSize();
      }
    }
  },
  created() {
    var that = this;
    if (that.activeName === 'wxLogin') {
      this.getWxTitckit()
    }
  },
  destroyed() {
    var that = this;
    window.clearInterval(that.timerFlag)
  },
  mounted() {
    EventBus.$on('open-login-dialog', () => {
      this.dialogVisible = true;
      this.getWxTitckit()
    });
    this.adjustDialogSize();
  },
  methods: {
    adjustDialogSize() {
      this.$nextTick(() => {
        if (this.$refs.dialogContent!==undefined){
          const contentWidth = this.$refs.dialogContent.offsetWidth;
          this.dialogWidth = contentWidth > 400 ? contentWidth : 400;
        }
      });
    },
    getNotifyMessage(title) {
      this.$notify({
        title: title,
        offset: 100,
        position: "bottom-right"
      });
    },
    bdRegisterButton() {
      var that = this;
      if (that.mobile.length !== 11) {
        this.getNotifyMessage("手机号格式错误")
        return;
      }
      if (that.code == null) {
        this.getNotifyMessage("验证码不能为空")
        return;
      }
      if (that.password !== that.passwordAgain) {
        this.getNotifyMessage("两次密码输入不一致")
        return;
      }
      if (that.openId === '' || that.openId == null) {
        this.getNotifyMessage("绑定失败,请重试或联系管理员")
        return;
      }
      bdRegister(that.mobile, that.password, that.code, that.openId).then(res => {
        if (res.data.code === 1) {
          this.getNotifyMessage(res.data.message)
          fnSetToken(res.data.data.token)
          this.$store.commit('doLogin')
          this.$router.go(0);
        }
      }).catch((err) => {
        this.getNotifyMessage("绑定账号异常,请联系管理员")
        console.log(err)
      })
    },
    forgetPasswordAccount() {
      var that = this;
      if (that.mobile.length !== 11) {
        this.getNotifyMessage("手机号格式错误")
        return;
      }
      if (that.code == null) {
        this.getNotifyMessage("验证码不能为空")
        return;
      }
      if (that.password !== that.passwordAgain) {
        this.getNotifyMessage("两次密码输入不一致")
        return;
      }
      retrievePassword(that.mobile, that.password, that.code).then(res => {
        if (res.data.code === 1) {
          this.getNotifyMessage(res.data.message)
        }
      }).catch((err) => {
        this.getNotifyMessage("重置密码异常,请联系管理员")
        console.log(err)
      })
    },
    regAccount() {
      var that = this;
      if (that.mobile.length !== 11) {
        this.getNotifyMessage("手机号格式错误")
        return;
      }
      if (that.code == null) {
        this.getNotifyMessage("验证码不能为空")
        return;
      }
      if (that.password !== that.passwordAgain) {
        this.getNotifyMessage("两次密码输入不一致")
        return;
      }
      register(that.mobile, that.password, that.code).then(res => {
        if (res.data.code === 1) {
          fnSetToken(res.data.data.token)
          this.$store.commit('doLogin')
          this.$router.go(0);
        }
      }).catch((err) => {
        this.getNotifyMessage("注册账户异常,请联系管理员")
        console.log(err)
      })
    },
    getBdCode() {
      var that = this;
      if (that.mobile.length !== 11) {
        this.getNotifyMessage("手机号格式错误")
        return;
      }
      that.codeCount = 30
      that.codeShow = true
      this.timer = setInterval(() => {
        if (this.codeCount > 0 && this.codeCount <= 30) {
          this.codeCount--
        } else {
          this.codeShow = false
          clearInterval(this.timer) // 清除定时器
          this.timer = null
        }
      }, 1000)
      // 发送短信
      sendBdSms(that.mobile).then(res => {
        if (res.data.code === 1) {
          this.$message.info("发送成功,请稍后")
          if (res.data.data.user == null || res.data.data.user === '') {
            that.bdPasswordShow = true
          } else {
            that.bdPasswordShow = false
          }
        } else {
          that.codeShow = false;
        }
      }).catch((err) => {
        this.getNotifyMessage("发送短信异常,请联系管理员")
        that.codeShow = false;
        console.log(err)
      })
    },
    getForgetPasswordCode() {
      var that = this;
      if (that.mobile.length !== 11) {
        this.getNotifyMessage("手机号格式错误")
        return;
      }
      that.codeCount = 30
      that.codeShow = true
      this.timer = setInterval(() => {
        if (this.codeCount > 0 && this.codeCount <= 30) {
          this.codeCount--
        } else {
          this.codeShow = false
          clearInterval(this.timer) // 清除定时器
          this.timer = null
        }
      }, 1000)
      // 发送短信
      retrieveCode(that.mobile).then(res => {
        console.log(res)
        if (res.data.code === 1) {
          this.getNotifyMessage("发送成功,请稍后")
        } else {
          that.codeShow = false;
        }
      }).catch((err) => {
        this.getNotifyMessage("发送短信异常,请联系管理员")
        that.codeShow = false;
        console.log(err)
      })
    },
    getCode() {
      var that = this;
      if (that.mobile.length !== 11) {
        this.getNotifyMessage("手机号格式错误")
        return;
      }
      that.codeCount = 30
      that.codeShow = true
      this.timer = setInterval(() => {
        if (this.codeCount > 0 && this.codeCount <= 30) {
          this.codeCount--
        } else {
          this.codeShow = false
          clearInterval(this.timer) // 清除定时器
          this.timer = null
        }
      }, 1000)
      // 发送短信
      sendCode(that.mobile).then(res => {
        console.log(res)
        if (res.data.code === 1) {
          this.getNotifyMessage("发送成功,请稍后")
        } else {
          that.codeShow = false;
        }
      }).catch((err) => {
        this.getNotifyMessage("发送短信异常,请联系管理员")
        that.codeShow = false;
        console.log(err)
      })
    },
    gotoWx() {
      var that = this;
      that.activeName = 'wxLogin'
      window.clearInterval(that.timerFlag)
      this.getWxTitckit()
    },
    gotoPlainLogin() {
      var that = this;
      that.activeName = 'plainLogin'
    },
    gotoForgetPassword() {
      var that = this;
      window.clearInterval(that.timerFlag)
      that.activeName = 'forgetPassword'
      that.codeCount = 30;
      that.codeShow = false
    },
    gotoRegLogin() {
      var that = this;
      window.clearInterval(that.timerFlag)
      that.activeName = 'regLogin'
      that.codeCount = 30;
      that.codeShow = false
    },
    changeTab(val) {
      console.log(val.name)
      var that = this;
      if (val.name === 'wxLogin') {
        this.getWxTitckit()
      }
      if (val.name === 'plainLogin' || val.name === 'regLogin') {
        window.clearInterval(that.timerFlag)
      }
    },
    getWxTitckit() {
      if (!this.dialogVisible){
        return;
      }
      var that = this;
      that.qrLoading = true;
      var flag = Math.floor(Math.random() * 999995999)
      wxLoginQr(flag).then(res => {
        that.qrLoading = false;
        that.ticketSrc = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.data.ticket
        that.timerFlag = window.setInterval(() => {
          setTimeout(this.getIsLoginStatus(flag, res.data.data.ticket), 0);
        }, 1000);
      }).catch((err) => {
        that.qrLoading = false;
        console.log(err)
      })
    },
    getIsLoginStatus(flag, ticket) {
      var that = this;
      wxLoginQrIsLogin(flag, ticket).then(res => {
        that.openId = res.data.data.openId
        if (res.data.data.status === 2) {
          if (res.data.data.token == null) {
            window.clearInterval(that.timerFlag)
            that.activeName = 'bdAccount'
          } else {
            fnSetToken(res.data.data.token)
            this.$store.commit('doLogin')
            this.$router.go(0);
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    loginMes() {
      login(this.mobile, this.password).then(res => {
        fnSetToken(res.data.data.token)
        this.$store.commit('doLogin')
        this.$router.go(0);
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: rgb(247, 248, 249) !important;
}

.el-dialog__wrapper {
  z-index: 100001 !important;
}

#loginDialog .el-dialog {
  z-index: 100010 !important;
}

#loginDialog .el-dialog__body {
  padding: 0 !important;
}

#loginDialog .el-dialog__header {
  padding: 0 !important;
}

@media (max-width: 768px) {
  #loginCard {
    width: 100% !important;
    margin: 120px auto !important;
  }

  #leftLoginPage {
    display: none !important;
  }

  .el-tabs__content {
    padding-bottom: 110px;
  }
}

#loginCard .el-card__body {
  margin: 0;
  padding: 0;
}

#loginCard .el-card {
  border: none !important;
  border-radius: 0 !important;
}

#loginCard .el-tabs__item {
  font-size: 15px;
  font-weight: bold;
  margin: 10px;
}

#loginTypeTabs .el-tabs__header .el-tabs__nav {
  border: 0 !important;
}
</style>
