import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		login: true
	},
	getters: {
		token: state => state.user.token
	},
	mutations: {
		// 登录
		doLogin(state) {
			state.login = true;
		},
		// 退出
		doLogout(state) {
			state.login = false;
		}
	},
	actions: {},
	modules: {}
})
