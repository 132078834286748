import request from '../util/request.js'

// 使用兑换码
export function secretKeyUse(secretKey,periods) {
	return request({
		url: '/secretKey/use?secretKey='+secretKey+'&periods='+periods,
		method: 'get'
	})
}

// 兑换码列表
export function secretKeyList(periods) {
	return request({
		url: '/secretKey/list?periods='+periods,
		method: 'get'
	})
}