import request from '../util/request.js'

// 登录
export function login(loginName, password) {
	return request({
		url: '/user/login',
		method: 'post',
		data: {
			"mobile": loginName,
			"password": password
		}
	})
}

// 订单
export function orderList() {
	return request({
		url: '/user/orderList',
		method: 'get'
	})
}

// 轮播图
export function bannerList() {
	return request({
		url: '/user/banner',
		method: 'get'
	})
}

// 搜索记录
export function searchLog() {
	return request({
		url: '/user/searchLog',
		method: 'get'
	})
}

// 收藏记录标签
export function tagList() {
	return request({
		url: '/collect/tag/tagList',
		method: 'get'
	})
}

// 收藏记录标签
export function tagListByUserInfo() {
	return request({
		url: '/collect/tag/tagListByUser',
		method: 'get'
	})
}

// 收藏记录删除单个
export function tagListDel(id) {
	return request({
		url: '/collect/tag/tagListDel?id=' + id,
		method: 'get'
	})
}

// 收藏记录删除全部
export function tagListDelAll(id) {
	return request({
		url: '/collect/tag/tagListDelAll?id=' + id,
		method: 'get'
	})
}

// 更新标签名称
export function updateTagName(tagName, newTagName) {
	return request({
		url: '/collect/tag/updateTagName',
		method: 'post',
		data: {
			"tagName": tagName,
			"newTagName": newTagName
		}
	})
}

// 收藏记录
export function saveCollectTag(topic, tagName, type, radioType) {
	return request({
		url: '/collect/tag/save',
		method: 'post',
		data: {
			"topic": topic,
			"tagName": tagName,
			"type": type,
			"radioType": radioType
		}
	})
}

// 获取微信二维码
export function wxLoginQr(flag) {
	return request({
		url: '/wx/user/login/qr?flag=' + flag,
		method: 'get'
	})
}


// 获取绑定微信二维码
export function wxLoginQrBd(flag) {
	return request({
		url: '/wx/user/login/bd?flag=' + flag,
		method: 'get'
	})
}

// 微信二维码是否扫描(轮询)
export function wxLoginQrIsLogin(flag,ticket) {
	return request({
		url: '/wx/user/isLogin?flag=' + flag+"&ticket="+ticket,
		method: 'get'
	})
}

// 微信二维码是否扫描-绑定账号用(轮询)
export function wxBdQrAccount(flag,ticket) {
	return request({
		url: '/wx/user/bdAccount?flag=' + flag+"&ticket="+ticket,
		method: 'get'
	})
}

// 微信绑定发送验证码
export function sendBdSms(loginName) {
	return request({
		url: '/wx/user/sendBdSms?mobile=' + loginName,
		method: 'get'
	})
}

// 绑定微信-新用户-注册
export function bdRegister(loginName, password, code, openId) {
	return request({
		url: '/wx/user/bdWxMobileOrWx',
		method: 'post',
		data: {
			"mobile": loginName,
			"password": password,
			"code": code,
			"openId": openId
		}
	})
}

// 注册发送验证码
export function sendCode(mobile) {
	return request({
		url: '/user/register/code',
		method: 'post',
		data: {
			"mobile": mobile
		}
	})
}

// saveUserInfo保存用户基本信息
export function saveUserInfo(industry, email) {
	return request({
		url: '/user/saveUserInfo',
		method: 'post',
		data: {
			"industry": industry,
			"email": email
		}
	})
}

// 用户信息
export function info() {
	return request({
		url: '/user/info',
		method: 'get'
	})
}

// 随机头像
export function getAvatar() {
	return request({
		url: '/user/avatar',
		method: 'get'
	})
}

// 注册
export function register(mobile, password, code) {
	return request({
		url: '/user/register',
		method: 'post',
		data: {
			"mobile": mobile,
			"password": password,
			"code": code
		}
	})
}

// 忘记密码发送验证码
export function retrieveCode(mobile) {
	return request({
		url: '/user/retrieve/code',
		method: 'post',
		data: {
			"mobile": mobile
		}
	})
}

//忘记密码提交
export function retrievePassword(mobile, password, code) {
	return request({
		url: '/user/retrieve/password',
		method: 'post',
		data: {
			"mobile": mobile,
			"password": password,
			"code": code
		}
	})
}

//用户重置密码
export function updatePassword(password) {
	return request({
		url: '/user/update/password',
		method: 'post',
		data: {
			"password": password
		}
	})
}

// 获取推荐code
export function userRecommendInfo() {
	return request({
		url: '/userRecommend/info',
		method: 'get'
	})
}

// 推荐人列表
export function userRecommendList() {
	return request({
		url: '/userRecommend/list',
		method: 'get'
	})
}

// 推荐
export function saveRecommend(code) {
	return request({
		url: '/userRecommend/save?code=' + code,
		method: 'get'
	})
}